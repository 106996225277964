<template>
  <div class="search-wrap">
    <div class="search-result-header page-width">
      <div class="search-input-wrap">
        <input type="text" placeholder="请输入关键字搜索" class="search-input" />
      </div>
      <h1 class="search-result-title">招商合作的搜索结果</h1>
    </div>
    <div class="search-result-middle">
      <div class="search-result-content">
        <ul class="search-item-list page-width" v-if="false">
          <li class="search-item" v-for="(item, index) in searchResuArr" :key="index">
            <div class="search-item-name">{{ item.title }}</div>
            <div class="search-item-content">
              {{ item.content }}
            </div>
            <div class="search-item-additional">
              <div class="search-item-time">{{ item.creatTime }}</div>
              <div class="search-item-category">{{ item.typeData }}</div>
            </div>
          </li>
        </ul>
        <div v-else>
          <div>
            <div class="no-result">
              <img class="no-result-img" src="https://deo.shopeesz.com/shopee/shopee-shopeecn-live-cn/static/img/empty.08da5e2.png" alt="">
              <p>没有找到您要搜索的内容</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchResuArr: [
        {
          title: '计分规则有更新! 准备报活动的卖家, 按这个优先级报很重要',
          content: `合理分配店铺产品资源。 以马来西亚、菲律宾、越南、泰国市场为例：①按照后台活动优先级，可从高到低分别设置为<em>A</em>+,` +
            `<em>A</em>` +
            `, B级别②将价格优势明显、库存量大的产品放入优先级高的活动③关注日常活动，选择符合要求的产品报名，提升曝光量 Shopee马来西亚市场 ○` +
            `<em>A</em>` +
            `+ 大促日活动：如11.11、12.12当天的活动 ○` +
            `<em>A</em>` +
            `大促预热活动：如8.21-9.8的9.9大促预热活动 ○ B 特定品类活动/定期活动：特定品类活动指非大促期间邀请相关品类卖家参与的活动`,
          creatTime: '2020/08/14',
          typeData: '运营干货'
        }
      ]
    }
  }
}
</script>
